@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

/* @font-face {
  font-family: 'BrandonText-Bold';
  src: url('/fonts/BrandonText-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'BrandonText-Black';
  src: url('/fonts/BrandonText-Black.otf') format('opentype');
}

@font-face {
  font-family: 'BrandonText-Medium';
  src: url('/fonts/BrandonText-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'BrandonText-Regular';
  src: url('/fonts/BrandonText-Regular.otf') format('opentype');
} */

#modal-root,
#error-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: stretch;
}

input.visit-input {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  border: none;
}

input.visit-input:focus {
  outline: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 6s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.custom-checkbox [type='checkbox']:after {
  border: 1px solid #714fff !important;
}

/* .checkmark {
  width: 18px;
  height: 18px;
  border: 0;
  border-radius: 6px;
} */

/* .checkmark:after {
  background-color: #714FFF;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark:checked:after {
  display: block;
  width: 18px;
  height: 18px;
  background-color: #714FFF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
} */

.custom-checkbox input {
  width: 0px;
  height: 0px;
  border: 0;
  margin-right: 12px;
}

.custom-checkbox input[type='checkbox']:after {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  border: 1.5px solid #b2b3b4;
  border-radius: 6px;
  background: #fff;
}

.custom-checkbox input[type='checkbox']:checked:after {
  display: block;
  width: 18px;
  height: 18px;
  content: '\2713';
  color: white;
  background-color: #714fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: 0;
  border-radius: 6px;
  padding-left: 3px;
}

.ReactTable .rt-thead.-filters input {
  border: 0;
}

/* .react-tabs__tab-list {
  border-bottom: 0;
}

.react-tabs__tab--selected {
  border: 0;
  border-bottom: 2px solid #714fff;
} */
.react-tabs__tab--selected {
  background: #fff;
  border-color: #714fff;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #714fff;
  margin: 0 0 10px;
  padding: 0;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  color: #714fff;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: url('../src/assets/images/cn.svg');
  color: #714fff;
}

input[type='date']:valid {
  border: 0px solid transparent;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid transparent;
}

.DateInput_input {
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: rgb(17 3 3 / 85%);
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

thead[class*='ant-table-thead'] th {
  font-weight: 600;
}

.ant-spin-dot-item {
  background-color: #714fff;
}

.ant-spin-text {
  color: #714fff;
  font-size: x-large;
  font-family: 'Montserrat';
  font-weight: 600;
}

/* .ant-switch-checked {
  background-color: #714fff;
}
.ant-switch {
  background-color: red;
} */
.ant-radio-checked .ant-radio-inner {
  border-color: #714fff !important;
  border: 2px solid #714fff;
  background-color: #714fff;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #714fff;
  border-color: #714fff;
  border: 2px solid #714fff;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #714fff;
  border: 2px solid #714fff;
}

.manage-doctor-input-box::placeholder {
  color: #69686a;
  font-size: 14px;
  font-family: Montserrat;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.ant-picker-suffix {
  color: #714fff;
  font-size: 1.25rem;
}

.ant-picker-date-panel {
  width: 100% !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.underlined-reason {
  text-decoration: underline;
}

.heading-wrap {
  white-space: normal;
  /* Allows the text to wrap onto multiple lines */
  word-wrap: break-word;
  /* Ensures long words are broken if necessary */
  overflow-wrap: break-word;
  /* Handles text overflow gracefully */
}

.custom-icon {
  color: #714fff;
  font-size: 22px;
  border-radius: 30%;
  border: 1px solid #714fff;
  padding: 2px;
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
}

.custom-icon:hover {
  color: #fff;
  /* Change color on hover */
  background-color: #714fff;
  /* Background color on hover */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transform: scale(1.1);
  /* Slightly enlarge the icon on hover */
}

.custom-dropdown {
  background-color: #d5c9ff !important;
  /* Use !important sparingly */
  border: 1.5px solid #714fff;
  border-radius: 6px;
  width: 100px;
  min-height: 2rem;
  line-height: normal;
  font-family: 'Montserrat';
}

.custom-dropdown-remarks {
  background-color: #d5c9ff !important;
  /* Use !important sparingly */
  border: 1.5px solid #714fff;
  border-radius: 6px;
  width: 150px;
  min-height: 35px;
  padding: 0;
  line-height: normal;
  font-family: 'Montserrat';
}

.custom-hover-button span {
  position: relative;
  z-index: 6; /* Ensures text is above the pseudo-element */
  mix-blend-mode: difference; /* Ensures text color adjusts dynamically */
}

.custom-hover-button {
  position: relative;
  overflow: hidden; /* Ensures pseudo-element stays within the button */
  color: black; /* Default text color */
  transition: color 0.7s ease; /* Smooth color transition */
  z-index: 1; /* Ensures text stays above the pseudo-element */
}

.custom-hover-button::before {
  content: ''; /* Required for pseudo-element to render */
  position: absolute;
  background: #714fff;
  width: 200%;
  height: 200%;
  top: 100%; /* Start outside the bottom */
  left: 100%; /* Start outside the right */
  transition: all 0.7s ease;
  z-index: 0; /* Keeps it behind the button text */
  border-radius: 50%; /* Start as a circular shape */
}
.custom-hover-button:hover {
  color: #fff !important; /* Change text color to white on hover */
}

.custom-hover-button:hover::before {
  top: -30px; /* Move to the top */
  left: -30px; /* Move to the left */
  border-radius: 12px;
}

.custom-hover-button:active::before {
  background: #714fff;
  transition: background 0s;
}

.custom-table-container .ant-table {
  border: none; /* Remove default table border */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional box-shadow */
}

/* Optional: Customize header and body row borders */
.custom-table-container .ant-table-thead > tr > th,
.custom-table-container .ant-table-tbody > tr > td {
  border: none; /* Remove cell borders */
}

/* Optional: Customize hover effect */
.custom-table-container .ant-table-tbody > tr:hover {
  background-color: #f9f9f9; /* Light hover background */
}
