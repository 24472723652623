ReactTable .rt-tbody .rt-td {
  border-right: 2px solid #e8e5f7;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #dedeeb;
}

.ReactTable {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border: transparent;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 2px solid #e8e5f7;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 2px solid #e8e5f7;
  padding: 7px;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
  border-bottom: 2px solid #714fff;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 2px solid #e8e5f7;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #3a3a49;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #e8e5f7;
}

.ReactTable .rt-thead.-header {
  background: #f3f0ff;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid #714fff;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
