/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: #eae7ff;
}

td,
th {
  border: 1px solid #714fff;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #714fff;
  color: white;
}

tr:nth-child(even) td {
  color: white;
} */
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 400px;
  font-family: arial, sans-serif;
  background-color: #eae7ff;
}

table tr th,
table tr td {
  border-right: 1px solid #714fff;
  border-bottom: 1px solid #714fff;
  padding: 5px;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid#714fff;
}

table tr th {
  background: #ebe7ff;
  border-top: 1px solid #714fff;
  text-align: left;
  color: #1e1e1e;
}

table tr td {
  background: #fff;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
